<template>
  <div>
    <div class="bj">
      <div class="bjContent">
        <div class="bjCont">
          <div class="iconfont icon-close cha" @click="delClick"></div>
          <div class="cont">
            <div class="contHead">地址</div>
            <div class="contBox">
              <div class="name"><span>*</span>收货人姓名</div>
              <div class="inpBox">
                <a-input placeholder="请输入收货人姓名" v-model="info.name" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>联系电话</div>
              <div class="inpBox">
                <a-input placeholder="请输入联系电话" v-model="info.mobile" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>地址信息</div>
              <a-cascader
                :options="options"
                :fieldNames="fieldNames"
                v-model="sqaddress"
                @change="onChange"
                placeholder="请选择省市区"
              />
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>详细地址</div>
              <div class="inpBox">
                <a-input placeholder="请输入详细地址" v-model="info.address" />
              </div>
            </div>
            <div class="seBox" @click="mrClick">
              <div class="fang dis-cent" :class="mr ? 'on' : ''">
                <div v-if="mr" class="iconfont icon-right"></div>
              </div>
              <div class="text">设为默认地址</div>
            </div>
          </div>
          <div class="gnBox">
            <div class="btn qx" @click="delClick">取消</div>
            <div class="btn" @click="fabuClick">确定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Select,
  Form,
  Input,
  Button,
  Upload,
  Cascader,
  Icon,
} from "ant-design-vue";
import { watch } from "less";
export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-cascader": Cascader,
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-upload": Upload,
    "a-icon": Icon,
  },
  data() {
    return {
      mr: false,
      fieldNames: {
        label: "name",
        value: "name",
        children: "children",
      },
      options: [
        // {
        //   value: "zhejiang",
        //   label: "Zhejiang",
        //   children: [
        //     {
        //       value: "hangzhou",
        //       label: "Hangzhou",
        //       children: [
        //         {
        //           value: "xihu",
        //           label: "West Lake",
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   value: "jiangsu",
        //   label: "Jiangsu",
        //   children: [
        //     {
        //       value: "nanjing",
        //       label: "Nanjing",
        //       children: [
        //         {
        //           value: "zhonghuamen",
        //           label: "Zhong Hua Men",
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      info: {},
      sqaddress: [],
    };
  },
  props: ["id"],
  mounted() {
    this.addressGet();
  },
  watch: {},
  activated() {},
  methods: {
    get() {
      this.$get("/web/address_get", {
        id: this.id,
      }).then((res) => {
        this.sqaddress = [res.data.province, res.data.city, res.data.area];
        this.info = res.data;
        this.$forceUpdate();
      });
    },
    addressGet() {
      this.$get("/common/area_gets", {
        pageNo: -1,
      }).then((res) => {
        this.options = res.data;
        if (this.id) {
          this.get();
        }
      });
    },
    mrClick() {
      this.mr = !this.mr;
    },
    onChange(value) {
      console.log(value);
      this.sqaddress = value;
      // this.province = value[0];
      // this.city = value[1];
      // this.area = value[2];
    },
    delClick() {
      this.$emit("show");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    popupScroll() {
      console.log("popupScroll");
    },
    fabuClick() {
      let data = this.info;
      data.province = this.sqaddress[0];
      data.city = this.sqaddress[1];
      data.area = this.sqaddress[2];
      if (!data.name) {
        this.$message.warning("请输入收货人姓名");
        return;
      }
      if (!data.mobile) {
        this.$message.warning("请输入联系电话");
        return;
      }
      if (this.sqaddress.length == 0) {
        this.$message.warning("请选择省市区");
        return;
      }
      if (!data.address) {
        this.$message.warning("请输入详细地址");
        return;
      }
      data.is_default = this.mr ? 1 : 0;
      console.log(data);
      data = JSON.stringify(data);
      this.$post("/web/address_save", {
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.$message.success("上传成功");
          this.$emit("show", res.data.status);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bjContent {
  display: flex;
  width: 100%;
  justify-content: center;

  .bjCont {
    background-color: #fff;
    width: 600px;
    margin-top: 90px;
    border-radius: 10px;
    position: relative;

    .cha {
      position: absolute;
      cursor: pointer;
      top: 10px;
      font-weight: 600;
      right: 20px;
      color: #9c9c9c;
    }

    .cont {
      padding: 20px 20px 10px;

      .contHead {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
      .seBox {
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 5px;
        cursor: pointer;
        .fang {
          width: 18px;
          border-radius: 3px;
          height: 18px;
          border: 1px solid #c9c9c9;
          box-sizing: border-box;
          .iconfont {
            font-size: 14px;
          }
        }
        .on {
          border: 1px solid #1186e6;
          background-color: #1186e6;
          color: #fff;
        }
        .text {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .contBox {
        padding-top: 0px;
        .ant-cascader-picker {
          width: 100%;
        }
        .name {
          margin-top: 15px;
          margin-bottom: 5px;

          span {
            color: #f30000;
          }
        }

        .tishi {
          line-height: 150%;
          color: #bfbfbf;
          font-size: 12px;
          margin-top: 5px;
          text-align: justify;
        }

        .upImg {
          margin-top: 10px;

          .text {
            margin-left: 20px;
            font-size: 12px;
          }

          .txt {
            margin-left: 5px;
            font-size: 12px;
            color: #999;
          }

          .imgs {
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;

            .img {
              width: calc(20% - 12px);
              margin-right: 15px;
              padding-top: calc(20% - 12px);
              position: relative;
              margin-bottom: 15px;
              overflow: unset;

              img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
              }

              .close {
                position: absolute;
                top: -3px;
                right: -3px;
                background-color: #b3b3b3;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: #fff;
              }
            }

            .img:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }

      .fuwenben {
        margin-top: 5px;
        height: 390px;
      }

      /deep/.w-e-text-container {
        height: 300px !important;
      }
    }

    .gnBox {
      border-top: 1px solid #d9d9d9;
      padding: 10px 24px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .btn {
        color: #fff;
        background: #e23;
        border: 1px solid #e23;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 15px;
        cursor: pointer;
      }

      .qx {
        background: unset;
        border: 1px solid rgb(180, 180, 180);
        color: #666;
      }
    }
  }
}
</style>
